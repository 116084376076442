// reset global styles
import "error-polyfill"
import "src/tailwind.css"
import { AmpliProvider } from "@cultureamp/analytics"
import { DynamicIntlProvider } from "@cultureamp/i18n-react-intl"
import { FrontendServices, defaultPreset } from "@cultureamp/next-services"
import { SharedCSSPatch } from "src/components/SharedCSSPatch/SharedCSSPatch"
import React from "react"

import { ampli } from "src/ampli"

import type { AppProps } from "next/app"

if (process.env.NODE_ENV !== "production") {
  require("../mocks")
}

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <FrontendServices
        services={defaultPreset}
        options={{
          datadogConfiguration: {
            applicationId: "3fa7e23f-127e-4f01-9a35-f77fc17c10c2",
            clientToken: "pub0e5f7caf4e6832e995230084e57bb45d",
            service: "unified-home",
          },
        }}
      >
        <DynamicIntlProvider>
          <AmpliProvider ampli={ampli}>
            <Component {...pageProps} />
          </AmpliProvider>
        </DynamicIntlProvider>
      </FrontendServices>
      <SharedCSSPatch />
    </>
  )
}

export default App
