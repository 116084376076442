import { useEffect } from "react"

// on initial client render link[data-n-p].removeAttr("data-n-p")
// the underlying problem is that "build-manifest.json@pages._app" doesn't contain the css files
// and because of that the <link> is tagged as not shared (data-n-p) here https://github.com/vercel/next.js/blob/aa931091561316674c3367aad34fa8a1ad10ab53/packages/next/pages/_document.tsx#L419
// and because of that it is removed on navigation
// and it might be because we force css modules on everything, even things that are not actual modules, fixing module naming convention and using default loaders should fix it
// til that happens we need to use SharedCSSPatch monkey patch
export const SharedCSSPatch = () => {
  useEffect(() => {
    document.querySelectorAll("link[rel=stylesheet][data-n-p]").forEach(el => {
      el.removeAttribute("data-n-p")
      el.setAttribute("data-n-g", "")
    })
  }, [])

  return null
}
